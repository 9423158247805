<template>
  <div>
    <v-dialog
      v-model="show"
      min-width="100vw"
      max-width="100vw"
      transition="dialog-top-transition"
      @click:outside="closeUpdateUserModal(false, '')"
    >
      <!-- @click:outside="closeModalUpdateContact()"⁄ -->
      <v-card rounded="lg">
        <v-card-title primary-title class="justify-center pa-10">
          <v-row justify="start" align="start">
            <div>
              <h2 class="black--text caption font-weight-bold text-h1">
                Mi cuenta
              </h2>
            </div>
          </v-row>
        </v-card-title>
        <v-card-text>
          <!-- {{ allLocation }} -->
          <v-row>
            <v-col cols="12" sm="3" class="pa-5 grey lighten-3">
              <v-row>
                <v-card
                  color="grey lighten-3"
                  v-if="userToUpdate"
                  width="100%"
                  height="60vh"
                  elevation="0"
                >
                  <v-container fill-height>
                    <v-row justify="center" align="center">
                      <v-col cols="12" justify="center" align="center">
                        <v-avatar color="indigo" width="20vh" height="20vh">
                          <img
                            :src="getProfileImg($store.state.userData.media)"
                            :alt="userToUpdate.name"
                          />
                        </v-avatar>
                      </v-col>
                      <edit-avatar class="ma-0" />
                      <v-col cols="12" sm="12" align="start" justify="start">
                        <UploadImageCloudinary />
                      </v-col>
                      <v-col cols="12" sm="12" align="start" justify="start">
                        <v-btn
                          text
                          color="orange"
                          class="caption text-capitalize"
                          @click="restorePassword(userToUpdate)"
                        >
                          Cambiar Contraseña
                          <v-icon class="px-2" small>
                            mdi-pencil-lock
                          </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="12" sm="12" align="start" justify="start">
                        <div>
                          <h4>
                            Fecha de registro:
                          </h4>
                        </div>
                        <v-row align="start" justify="start">
                          <v-col cols="12" class="px-5 py-2">
                            <div>
                              {{ getSpanishDate(userToUpdate.created_at) }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="12" sm="12" align="start" justify="start">
                        <div>
                          <h4>
                            Ultima Actualización:
                          </h4>
                        </div>
                        <v-row align="start" justify="start">
                          <v-col cols="12" class="px-5 py-2">
                            <div>
                              {{ getSpanishDate(userToUpdate.updated_at) }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-row>
            </v-col>
            <v-col cols="12" sm="9">
              <v-form ref="updateContactForm" v-model="updateContactFormValid">
                <v-row justify="space-between" align="center">
                  <v-col cols="12" sm="">
                    <div class="mb-3">
                      <h3>
                        Nombre
                      </h3>
                    </div>
                    <v-text-field
                      v-model="name"
                      :rules="nameRules"
                      :disabled="isLoadingUpdateUser"
                      outlined
                      placeholder="Nombre"
                      required
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <div class="mb-3">
                      <h3>
                        Usuario
                      </h3>
                    </div>
                    <v-text-field
                      v-model="userName"
                      disabled
                      filled
                      placeholder="Usuario"
                      required
                    />
                  </v-col>
                </v-row>
                <v-row justify="space-between" align="center">
                  <v-col cols="12" sm="6">
                    <div class="mb-3">
                      <h3>
                        Correo
                      </h3>
                    </div>
                    <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      filled
                      placeholder="Correo"
                      required
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <div class="mb-3">
                      <h3>
                        Teléfono
                      </h3>
                    </div>
                    <v-text-field
                      v-model="phone"
                      :disabled="isLoadingUpdateUser"
                      outlined
                      placeholder="Teléfono"
                      required
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
          <small class="red--text">{{ messageError }}</small>
        </v-card-text>
        <v-card-actions>
          <v-row justify="end" align="end">
            <v-col cols="12" sm="4">
              <v-btn
                class="mb-15 rounded-lg"
                color="primary"
                text
                :disabled="isLoadingUpdateUser"
                block
                large
                @click="closeUpdateUserModal(false, '')"
              >
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="12" sm="4">
              <v-btn
                class="mb-15 rounded-lg"
                color="primary"
                :disabled="isLoadingUpdateUser"
                :loading="isLoadingUpdateUser"
                block
                large
                @click="validateUpdateUserForm()"
              >
                Actualizar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <restore-password-user-modal
      :visible="showRestoreUserModal"
      :user-to-restore="userToUpdate"
      @close="onCloseRestoreUserModal"
    />
  </div>
</template>
<script>
import moment from "moment";
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  components: {
    RestorePasswordUserModal: () =>
      import("@/components/user/UserRestorePassword.vue"),
    UploadImageCloudinary: () =>
      import("@/components/upload/UploadImageCloudinary.vue"),
    EditAvatar: () => import("@/components/user/EditAvatarComponent.vue")
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    userToUpdate: {
      type: Object,
      default: () => null
    },
    realEstate: {
      type: Array,
      default: () => []
    },
    allLocation: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      updateContactFormValid: true,
      isLoadingUpdateUser: false,
      updatedData: null,
      messageError: "",
      statusUpdate: "",
      role: "",
      name: "",
      nameRules: [v => !!v || "El nombre es requerido"],
      userName: "",
      userNameRules: [v => !!v || "El usuario es requerido"],
      phone: "",
      phoneRules: [
        v => !!v || "El teléfono es requerido",
        // v => v.length >= 10 || "The phone number is incorrect",
        // v => v.length <= 10 || "The phone number is incorrect",
        v =>
          Number.isInteger(Number(v)) || "El teléfono no puede contener letras"
      ],
      email: "",
      emailRules: [
        v => !!v || "E-mail es requerido",
        v => /.+@.+/.test(v) || "E-mail no valido"
      ],
      showRestoreUserModal: false,
      userToRestore: null,
      dataEdited: null,
      showTooltip: false
    };
  },
  computed: {
    ...mapState({
      contactListSt: state => state.contacts.contactList
    }),
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close", this.updatedData, this.dataEdited);
        }
      }
    }
  },
  watch: {
    userToUpdate(newValue) {
      if (newValue) {
        this.name = this.$store.state.userData.name
          ? this.$store.state.userData.name
          : "";
        this.email = newValue.email ? newValue.email : "";
        this.phone = newValue.phone ? newValue.phone : "";
        this.role = newValue.role ? newValue.role : "";
      } else {
        this.name = "";
        this.email = "";
        this.phone = "";
        this.role = "";
        this.selectedRealEstate = [];
      }
    }
  },
  methods: {
    ...mapActions({
      updateContactByIdStore: "contacts/updateContactById"
    }),
    ...mapMutations({
      setContactStore: "contacts/setContact"
    }),
    closeUpdateUserModal(val, dataEdited) {
      this.messageError = "";
      this.dataEdited = dataEdited;
      this.show = val;
      this.$refs.updateContactForm.resetValidation();
    },
    onCloseRestoreUserModal(canceled) {
      if (canceled) {
        this.userToRestore = null;
        this.showRestoreUserModal = false;
      } else {
        this.userToRestore = null;
        this.showRestoreUserModal = false;
        this.closeUpdateUserModal(false, "password");
      }
    },
    restorePassword(item) {
      this.userToRestore = item;
      this.showRestoreUserModal = true;
    },
    validateUpdateUserForm() {
      if (this.$refs.updateContactForm.validate()) {
        this.updateContact();
      } else {
        this.messageError = "Por favor, verifica los errores.";
      }
    },
    updateContact() {
      this.isLoadingUpdateUser = true;
      try {
        const info = {
          role: this.role,
          data: {
            _id: this.userToUpdate._id,
            user_id: this.userToUpdate.user_id,
            table: this.role,
            role: this.role,
            name: this.name,
            email: this.email,
            phone: this.phone
          }
        };
        this.updateContactByIdStore(info).then(res => {
          if (res && res.user != undefined) {
            this.statusUpdate = "Success";
            this.isLoadingUpdateUser = false;
            this.updatedData = res.user;
            this.closeUpdateUserModal(false, "profile");
          } else {
            this.updatedData = null;
            this.statusUpdate = "Error";
            this.isLoadingUpdateUser = false;
          }
        });
      } catch (error) {
        this.updatedData = null;
        this.statusUpdate = "Error";
        this.isLoadingUpdateUser = false;
      }
    },
    getProfileImg(m) {
      let media = m !== undefined && m !== null && m !== "" ? m : {};
      if (media.hasOwnProperty("featured_image")) {
        if (media.featured_image.hasOwnProperty("src")) {
          if (
            media.featured_image.src !== null &&
            media.featured_image.src !== ""
          ) {
            return media.featured_image.src;
          } else {
            return "https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/OOjs_UI_icon_userAvatar-progressive.svg/600px-OOjs_UI_icon_userAvatar-progressive.svg.png";
          }
        } else if (media.featured_image.hasOwnProperty("src_avatar")) {
          if (
            media.featured_image.src_avatar !== null &&
            media.featured_image.src_avatar !== ""
          ) {
            return media.featured_image.src_avatar;
          } else {
            return "https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/OOjs_UI_icon_userAvatar-progressive.svg/600px-OOjs_UI_icon_userAvatar-progressive.svg.png";
          }
        } else {
          return "https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/OOjs_UI_icon_userAvatar-progressive.svg/600px-OOjs_UI_icon_userAvatar-progressive.svg.png";
        }
      } else {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/OOjs_UI_icon_userAvatar-progressive.svg/600px-OOjs_UI_icon_userAvatar-progressive.svg.png";
      }
    },
    getSpanishDate(date) {
      if (date === undefined || date === null || date === "") {
        return moment(new Date())
          .locale("es")
          .format("LL");
      } else {
        return moment(new Date(date))
          .locale("es")
          .format("LL");
      }
    }
  }
};
</script>

<style scoped>
.v-dialog {
  border-radius: 24px !important;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: none !important;
}
.v-expansion-panel {
  box-shadow: none;
}
.note-project-assing-text {
  font-size: 14px;
  color: grey;
}
.note-project-assing-text-btn {
  font-size: 14px;
  font-weight: normal;
  text-transform: lowercase;
  color: #4ea6da;
  padding-bottom: 1000px;
  text-decoration: underline;
}

.note-project-assing-text-list {
  font-size: 14px;
  font-weight: 400;
}
.note-project-assing-text-tooltip-title {
  font-size: 18px;
}
</style>
